import API from "./API";

export const addQuestionApi = (payload) => {
  return API.post("/question", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getQuestionApi = (payload) => {
  return API.get(`/question/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getQuestionByIndexApi = (payload) => {
  return API.get(`/question/${payload?.quizId}/${payload?.questionIndex}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateQuestionApi = (payload) => {
  return API.put("/question", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteQuestionApi = (payload) => {
  return API.delete(`/question/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
