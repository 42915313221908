import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addCertificateAPI,
  addCustomDomainAPI,
  checkCertificateStatusAPI,
  checkCustomDomainStatusAPI,
  checkCustomDomainAppStatusAPI,
  getCustomDomainDeleteAPI,
  getCustomDomainDetailsAPI,
  getTenantCustomNameAPI,
} from "../api/CustomDomainApi";

const initialState = {
  userData: null,
  loading: false,
  certificateStatusLoading: false,
  domainStatusLoading: false,
  domainAppStatusLoading: false,
  domainDeleteLoading: false,
};

export const addCertificate = createAsyncThunk(
  "post/addCertificate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addCertificateAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getTenantCustomName = createAsyncThunk(
  "get/customdomain/getcustomdomain",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getTenantCustomNameAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addCustomDomain = createAsyncThunk(
  "post/addCustomDomain",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addCustomDomainAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const checkCertificateStatus = createAsyncThunk(
  "post/checkCertificateStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await checkCertificateStatusAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const checkCustomDomainStatus = createAsyncThunk(
  "post/checkCustomDomainStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await checkCustomDomainStatusAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const checkCustomDomainAppStatus = createAsyncThunk(
  "post/checkCustomDomainAppStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await checkCustomDomainAppStatusAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getCustomDomainDetails = createAsyncThunk(
  "post/getCustomDomainDetails",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCustomDomainDetailsAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteCustomDomain = createAsyncThunk(
  "delete/customdomain",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCustomDomainDeleteAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const customDomainSlice = createSlice({
  name: "customDomain",
  initialState,
  reducers: {
    setCertificateStatusLoadingFalse: (state, action) => {
      state.certificateStatusLoading = false;
    },
    setCertificateStatusLoadingTrue: (state, action) => {
      state.certificateStatusLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCertificate.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(addCertificate.fulfilled, (state, action) => {
      //   console.log(action.payload.response);
      //   state.userData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(addCertificate.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTenantCustomName.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getTenantCustomName.fulfilled, (state, action) => {
      //   state.userData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getTenantCustomName.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addCustomDomain.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(addCustomDomain.fulfilled, (state, action) => {
      //   state.userData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(addCustomDomain.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(checkCertificateStatus.pending, (state) => {
      state.certificateStatusLoading = false;
    });
    builder.addCase(checkCertificateStatus.fulfilled, (state, action) => {
      //   state.userData = action.payload.response;
      state.certificateStatusLoading = true;
    });
    builder.addCase(checkCertificateStatus.rejected, (state) => {
      state.certificateStatusLoading = false;
    });

    builder.addCase(checkCustomDomainStatus.pending, (state) => {
      state.domainStatusLoading = false;
    });
    builder.addCase(checkCustomDomainStatus.fulfilled, (state, action) => {
      //   state.userData = action.payload.response;
      state.domainStatusLoading = true;
    });
    builder.addCase(checkCustomDomainStatus.rejected, (state) => {
      state.domainStatusLoading = false;
    });

    builder.addCase(checkCustomDomainAppStatus.pending, (state) => {
      state.domainAppStatusLoading = false;
    });
    builder.addCase(checkCustomDomainAppStatus.fulfilled, (state, action) => {
      //   state.userData = action.payload.response;
      state.domainAppStatusLoading = true;
    });
    builder.addCase(checkCustomDomainAppStatus.rejected, (state) => {
      state.domainAppStatusLoading = false;
    });

    builder.addCase(getCustomDomainDetails.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getCustomDomainDetails.fulfilled, (state, action) => {
      //   state.userData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getCustomDomainDetails.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteCustomDomain.pending, (state) => {
      state.domainDeleteLoading = false;
    });
    builder.addCase(deleteCustomDomain.fulfilled, (state, action) => {
      //   state.userData = action.payload.response;
      state.domainDeleteLoading = true;
    });
    builder.addCase(deleteCustomDomain.rejected, (state) => {
      state.domainDeleteLoading = false;
    });
  },
});

export default customDomainSlice.reducer;
export const { setCertificateStatusLoadingFalse,setCertificateStatusLoadingTrue } = customDomainSlice.actions;
