import { Provider } from "react-redux";

import { ThemeProvider } from "@mui/material";
import "./App.css";

import { theme } from "./theme";
import MainRouter from "./Router/MainRouter";
import store from "./stores/store";
import { useEffect } from "react";
import { getTenantCustomNameAPI } from "./api/CustomDomainApi";
import Cookies from "js-cookie";


function App() {

  const fetchCustomDomain = async () => {
    const hostname = window.location.hostname;
    const res = await getTenantCustomNameAPI(hostname);
    console.log("asdsadasd")
    console.log(res)
    console.log(res?.data)
    if (res?.data?.response?.tenantName)
      Cookies.set("custom_tenant", res?.data?.response?.tenantName, {
        path: "/",
        domain: hostname,
        // domain: "localhost",
        secure: true,
        sameSite: "Strict",
      });
      window.location.reload();
  };

  useEffect(() => {
    const customTenant = Cookies.get("custom_tenant");

    if (
      !(
        window.location.hostname.includes("e-brainee.com") ||
        window.location.hostname.includes("ebrainee.com") ||
        customTenant ||
        window.location.hostname.includes("localhost")
      )
    ) {
      fetchCustomDomain();
    }
  }, []);
  return (
    <div>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MainRouter></MainRouter>
          {/* <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding />
          </ConnectComponentsProvider> */}
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
