import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserAPI, getUserRoleBasedAPI, surveyAPI } from "../api/UserApi";

const initialState = {
  userData: null,
  users: null,
  surevy: null,
  loading: false,
  userRoleBased: null,
};

export const ansSurvey = createAsyncThunk(
  "user/survey",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await surveyAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getUser = createAsyncThunk(
  "user/getUser",
  async (rejectWithValue) => {
    try {
      const response = await getUserAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getUserRoleBased = createAsyncThunk(
  "user/getUserRoleBased",
  async (rejectWithValue) => {
    try {
      const response = await getUserRoleBasedAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const rnrSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    //get user
    builder.addCase(getUser.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.userData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserRoleBased.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserRoleBased.fulfilled, (state, action) => {
      state.userRoleBased = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getUserRoleBased.rejected, (state) => {
      state.loading = false;
    });

    //add surevy ans
    builder.addCase(ansSurvey.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(ansSurvey.fulfilled, (state, action) => {
      state.surevy = action.payload.response;
      state.loading = true;
    });
    builder.addCase(ansSurvey.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default rnrSlice.reducer;
