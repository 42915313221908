import API from "./API";

export const getTwilioInfoAPI = () => {
  return API.get(`/twilio`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const updateTwilioInfoAPI = (payload) => {
  return API.put("/twilio", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};
