import API from "./API";

export const getCourseResourcesAPI = (courseId) => {
  return API.get(`/course/resource/${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addCourseResourceAPI = async (data, onUploadProgress) => {
  try {
    const response = await API.post(`/course/resource`, data, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
      },
      onUploadProgress: (progressEvent_1) => {
        const progress = Math.round((progressEvent_1.loaded * 100) / progressEvent_1.total);
        if (onUploadProgress) {
          onUploadProgress(progress);
        }
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteCourseResourcesAPI = (resourceId) => {
  return API.delete(`/course/resource/${resourceId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
