import API from "./API";

export const addToCartAPI = (courseId) => {
  return API.post(`/cart?courseId=${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCartAPI = () => {
  return API.get("/cart/course")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteCartAPI = (courseId) => {
  return API.delete(`/cart?courseId=${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCartCountAPI = () => {
  return API.get("/cart/count")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
