import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addRnRAPI,
  deleteRnRAPI,
  getAllRnRAPI,
  getRnRAPI,
} from "../api/RandRApi";

const initialState = {
  rnr: {},
  rnrs: [],
  loading: false,
  addRnrLoading: null,
};

export const getRnR = createAsyncThunk(
  "ratingandreview/getRnR",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getRnRAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getAllRnR = createAsyncThunk(
  "ratingandreview/getAllRnR",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getAllRnRAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addRnR = createAsyncThunk(
  "ratingandreview/addRnR",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addRnRAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteRnR = createAsyncThunk(
  "ratingandreview/deleteRnR",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await deleteRnRAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const rnrSlice = createSlice({
  name: "rnr",
  initialState,
  extraReducers: (builder) => {
    //GET rnr
    builder.addCase(getRnR.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getRnR.fulfilled, (state, action) => {
      state.rnrs = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getRnR.rejected, (state) => {
      state.loading = false;
    });

    //GET ALL rnr
    builder.addCase(getAllRnR.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllRnR.fulfilled, (state, action) => {
      state.rnrs = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getAllRnR.rejected, (state) => {
      state.loading = false;
    });

    //add rnr
    builder.addCase(addRnR.pending, (state) => {
      state.addRnrLoading = false;
    });
    builder.addCase(addRnR.fulfilled, (state, action) => {
      state.rnr = action.payload.response;
      state.rnrs
        ? state.rnrs.push(action.payload.response)
        : (state.rnrs = [action.payload.response]);
      state.addRnrLoading = true;
    });
    builder.addCase(addRnR.rejected, (state) => {
      state.addRnrLoading = null;
    });

    //delete rnr
    builder.addCase(deleteRnR.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRnR.fulfilled, (state, action) => {
      state.rnr = action.payload;

      const { arg } = action.meta;
      if (arg) {
        state.rnrs = state.rnrs.filter(
          (item) => item.ratingAndReviewId !== arg
        );
      }
      state.loading = true;
    });
    builder.addCase(deleteRnR.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default rnrSlice.reducer;
