import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addQuestionApi,
  getQuestionApi,
  getQuestionByIndexApi,
  updateQuestionApi,
  deleteQuestionApi,
} from "../api/questionApi";

const initialState = {
  question: [],
  singlequestion: null,
  addquestionLoad: false,
  getquestionLoad: false,
  updatequestionLoad: false,
  deletequestionLoad: false,
};

export const addQuestion = createAsyncThunk(
  "question/addQuestion",
  async (payload) => {
    const res = await addQuestionApi(payload);
    return res.data;
  }
);

export const getQuestion = createAsyncThunk(
  "question/getQuestion",
  async (payload) => {
    const res = await getQuestionApi(payload);
    return res.data;
  }
);

export const getQuestionByIndex = createAsyncThunk(
  "question/getQuestionByIndex",
  async (payload) => {
    const res = await getQuestionByIndexApi(payload);
    return res.data;
  }
);

export const updateQuestion = createAsyncThunk(
  "question/updateQuestion",
  async (payload) => {
    const res = await updateQuestionApi(payload);
    return res.data;
  }
);

export const deleteQuestion = createAsyncThunk(
  "question/deleteQuestion",
  async (payload) => {
    const res = await deleteQuestionApi(payload);
    return res.data;
  }
);

const questionSlice = createSlice({
  name: "question",
  initialState,
  extraReducers: (builder) => {
    //add question
    builder.addCase(addQuestion.pending, (state, action) => {
      state.addquestionLoad = false;
    });
    builder.addCase(addQuestion.fulfilled, (state, action) => {
      state.addquestionLoad = true;
    });
    builder.addCase(addQuestion.rejected, (state, action) => {
      state.addquestionLoad = false;
    });

    //get question
    builder.addCase(getQuestion.pending, (state, action) => {
      state.getquestionLoad = true;
    });
    builder.addCase(getQuestion.fulfilled, (state, action) => {
      state.question = action.payload.response;
      state.getquestionLoad = false;
    });
    builder.addCase(getQuestion.rejected, (state, action) => {
      state.getquestionLoad = false;
    });

    //get question by id
    builder.addCase(getQuestionByIndex.pending, (state, action) => {
      state.singlequestion = null;
      state.getquestionLoad = true;
    });
    builder.addCase(getQuestionByIndex.fulfilled, (state, action) => {
      state.singlequestion = action.payload.response;
      state.getquestionLoad = false;
    });
    builder.addCase(getQuestionByIndex.rejected, (state, action) => {
      state.singlequestion = null;
      state.getquestionLoad = false;
    });

    //update question
    builder.addCase(updateQuestion.pending, (state, action) => {
      state.updatequestionLoad = true;
    });
    builder.addCase(updateQuestion.fulfilled, (state, action) => {
      state.question = state.question.map((value) => {
        return value.questionId === action?.payload?.response?.questionId
          ? action?.payload?.response
          : value;
      });
      state.updatequestionLoad = false;
    });
    builder.addCase(updateQuestion.rejected, (state, action) => {
      state.updatequestionLoad = false;
    });

    //delete question
    builder.addCase(deleteQuestion.pending, (state) => {
      state.deletequestionLoad = true;
    });
    builder.addCase(deleteQuestion.fulfilled, (state, action) => {
      state.deletequestionLoad = false;

      const index = state.question.findIndex((value) => {
        return value.questionId === action.payload.response.questionId;
      });

      state.question.splice(index, 1);
    });
    builder.addCase(deleteQuestion.rejected, (state) => {
      state.deletequestionLoad = false;
    });
  },
});

export default questionSlice.reducer;
