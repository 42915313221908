import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addWishlistAPI,
  deleteWishlistAPI,
  getWishlistAPI,
} from "../api/wishlistApi";

const initialState = {
  wishlists: [],
  wishlist: 0,
  loading: false,
};

export const getWishlist = createAsyncThunk(
  "wishlist/getWishlist",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getWishlistAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteWishlist = createAsyncThunk(
  "wishlist/deleteWishlist",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteWishlistAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addWishlist = createAsyncThunk(
  "wishlist/addWishlist",
  async (id, { rejectWithValue }) => {
    try {
      const response = await addWishlistAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  extraReducers: (builder) => {
    //GET wishlist
    builder.addCase(getWishlist.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getWishlist.fulfilled, (state, action) => {
      state.wishlists = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getWishlist.rejected, (state) => {
      state.loading = false;
    });

    //add wishlist
    builder.addCase(addWishlist.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(addWishlist.fulfilled, (state, action) => {
      state.wishlist = action.payload.response;
      state.loading = true;
    });
    builder.addCase(addWishlist.rejected, (state) => {
      state.loading = false;
    });

    //delete wishlist
    builder.addCase(deleteWishlist.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteWishlist.fulfilled, (state, action) => {
      state.wishlist = action.payload;
      //   const { arg } = action.meta;
      //   if (arg) {
      //     state.allTopics = state.allTopics.filter(
      //       (item) => item.topicId !== arg
      //     );
      //   }
      state.loading = true;
    });
    builder.addCase(deleteWishlist.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default wishlistSlice.reducer;
