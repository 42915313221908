import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getWebsiteInfoAPI, updateWebsiteInfoAPI } from "../api/WebsiteInfoApi";

const initialState = {
  websiteInfoData: null,
  loading: false,
};

export const updateWebsite = createAsyncThunk(
  "update/website",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateWebsiteInfoAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getWebsiteInfo = createAsyncThunk(
  "get/websiteInfo",
  async (tenantId, { rejectWithValue }) => {
    try {
      const response = await getWebsiteInfoAPI(tenantId);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const websiteInfoSlice = createSlice({
  name: "website",
  initialState,
  extraReducers: (builder) => {
    //get user
    builder.addCase(getWebsiteInfo.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getWebsiteInfo.fulfilled, (state, action) => {
      state.websiteInfoData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getWebsiteInfo.rejected, (state) => {
      state.loading = false;
    });

    //update user
    builder.addCase(updateWebsite.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateWebsite.fulfilled, (state, action) => {
      state.websiteInfoData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(updateWebsite.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default websiteInfoSlice.reducer;
