import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addOptionAPI,
  deleteOptionAPI,
  getAllOptionAPI,
  updateOptionAPI,
} from "../api/OptionsApi";

const initialState = {
  options: [],
  addoptionsLoad: false,
  getoptionsLoad: false,
  updateoptionsLoad: false,
  deleteoptionsLoad: false,
};

export const getOptions = createAsyncThunk(
  "option/getOptions",
  async (payload) => {
    const res = await getAllOptionAPI(payload);
    return res.data;
  }
);

export const addOptions = createAsyncThunk(
  "option/addOptions",
  async (payload) => {
    const res = await addOptionAPI(payload);
    return res.data;
  }
);

export const updateOptions = createAsyncThunk(
  "option/updateOptions",
  async (payload) => {
    const res = await updateOptionAPI(payload);
    return res.data;
  }
);

export const deleteOptions = createAsyncThunk(
  "option/deleteOptions",
  async (payload) => {
    const res = await deleteOptionAPI(payload);
    return res.data;
  }
);

const optionSlice = createSlice({
  name: "option",
  initialState,
  extraReducers: (builder) => {
    //add options
    builder.addCase(addOptions.pending, (state, action) => {
      state.addoptionsLoad = false;
    });
    builder.addCase(addOptions.fulfilled, (state, action) => {
      state.addoptionsLoad = true;
    });
    builder.addCase(addOptions.rejected, (state, action) => {
      state.addoptionsLoad = false;
    });

    //get options
    builder.addCase(getOptions.pending, (state, action) => {
      state.getoptionsLoad = false;
    });
    builder.addCase(getOptions.fulfilled, (state, action) => {
      state.getoptionsLoad = true;
      state.options = action.payload.response;
    });
    builder.addCase(getOptions.rejected, (state, action) => {
      state.getoptionsLoad = false;
    });

    //update options
    builder.addCase(updateOptions.pending, (state, action) => {
      state.updateoptionsLoad = false;
    });
    builder.addCase(updateOptions.fulfilled, (state, action) => {
      state.updateoptionsLoad = true;
      state.options = state.options.map((value) => {
        return value.optionsid === action?.payload?.response?.optionsid
          ? action?.payload?.response
          : value;
      });
    });
    builder.addCase(updateOptions.rejected, (state, action) => {
      state.updateoptionsLoad = false;
    });

    //delete options
    builder.addCase(deleteOptions.pending, (state, action) => {
      state.deleteoptionsLoad = false;
    });
    builder.addCase(deleteOptions.fulfilled, (state, action) => {
      state.deleteoptionsLoad = true;

      const index = state.options.findIndex((value) => {
        return value.optionsid === action.payload.response.optionsid;
      });

      state.options.splice(index, 1);
    });
    builder.addCase(deleteOptions.rejected, (state, action) => {
      state.deleteoptionsLoad = false;
    });
  },
});

export default optionSlice.reducer;
