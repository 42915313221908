import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  downloadCertficateAPI,
  getCertificateAPI,
  getResultAPI,
  startTestAPI,
  stopTestAPI,
} from "../api/testApi";
import moment from "moment";

const initialState = {
  test: null,
  testTime: null,
  startTestFlag: false,
  candidateId: null,
  startTestLoading: false,
  stopTestFlag: false,
  result: null,
  downloadCertificateFlag: false,
  downloadCertificateData: false,
  certificateData: null,
  listCertificateData: null,
};

export const startTest = createAsyncThunk("test/startTest", async (payload) => {
  const res = await startTestAPI(payload);
  return res.data;
});

export const stopTest = createAsyncThunk("test/stopTest", async (payload) => {
  const res = await stopTestAPI(payload);
  return res.data;
});

export const downloadCertificate = createAsyncThunk(
  "test/downloadCertificate",
  async (payload) => {
    const res = await downloadCertficateAPI(payload);
    return res.data;
  }
);

export const getResult = createAsyncThunk("test/result", async (payload) => {
  const res = await getResultAPI(payload);
  return res.data;
});

export const getCertificate = createAsyncThunk(
  "test/getCertificate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCertificateAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    setResultFlag: (state) => {
      state.stopTestFlag = false;
      state.certificateData = null;
    },
  },
  extraReducers: (builder) => {
    //start test
    builder.addCase(startTest.pending, (state) => {
      state.startTestLoading = true;
    });
    builder.addCase(startTest.fulfilled, (state, action) => {
      state.startTestLoading = false;
      state.startTestFlag = true;
      state.test = action?.payload?.response;

      var endTime = moment(action.payload.response?.testEndTime).format(
        "DD MM YYYY hh:mm:ss"
      );
      var startTime = moment(action.payload.response?.testStartTime).format(
        "DD MM YYYY hh:mm:ss"
      );
      endTime = moment(endTime);
      startTime = moment(startTime);
      state.testTime = endTime.diff(startTime, "minutes");
      state.candidateId = action.payload.response?.candidateId;
    });
    builder.addCase(startTest.rejected, (state) => {
      state.startTestLoading = false;
      state.startTestFlag = true;
    });

    //stop test
    builder.addCase(stopTest.pending, (state) => {
      state.stopTestFlag = false;
    });
    builder.addCase(stopTest.fulfilled, (state, action) => {
      state.stopTestFlag = true;
      state.startTestFlag = false;
    });
    builder.addCase(stopTest.rejected, (state) => {
      state.stopTestFlag = false;
    });

    //download certificate
    builder.addCase(downloadCertificate.pending, (state) => {
      state.downloadCertificateFlag = false;
    });
    builder.addCase(downloadCertificate.fulfilled, (state, action) => {
      state.downloadCertificateFlag = true;
      state.certificateData = action.payload.response;
      // state.downloadCertificateData =
      //   action.payload.response == 1 ? true : false;
    });
    builder.addCase(downloadCertificate.rejected, (state) => {
      state.downloadCertificateFlag = false;
    });

    //result certificate
    builder.addCase(getResult.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getResult.fulfilled, (state, action) => {
      state.result = action.payload.response;
      state.loading = true;

      // state.downloadCertificateData =
      //   action.payload.response == 1 ? true : false;
    });
    builder.addCase(getResult.rejected, (state) => {
      state.loading = false;
    });

    //GET LIST OF CERTIFICATE
    builder.addCase(getCertificate.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getCertificate.fulfilled, (state, action) => {
      state.listCertificateData = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getCertificate.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setResultFlag } = testSlice.actions;
export default testSlice.reducer;
