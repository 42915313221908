import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addPurchaseCourseAPI,
  enrollCourseAPI,
  getPurchaseCourseAPI,
} from "../api/PurchaseCourseApi";

const initialState = {
  purchasedCourses: [],
  purchasedCourse: null,
  enrollCourse: null,
  loadingEnroll: null,
};

export const addPurchaseCourse = createAsyncThunk(
  "purchasecourse/addPurchasecourse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addPurchaseCourseAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getPurchaseCourse = createAsyncThunk(
  "purchasecourse/getPurchaseCourse",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getPurchaseCourseAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const enrollCourse = createAsyncThunk(
  "enrollcourse/enrollcourse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await enrollCourseAPI(payload);
      window.location = "/thank-you-for-enrolling";
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  extraReducers: (builder) => {
    // add purchase
    builder.addCase(addPurchaseCourse.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(addPurchaseCourse.fulfilled, (state, action) => {
      state.purchasedCourse = action.payload.response;
      state.loading = true;
    });
    builder.addCase(addPurchaseCourse.rejected, (state) => {
      state.loading = false;
    });

    //GET purchase
    builder.addCase(getPurchaseCourse.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getPurchaseCourse.fulfilled, (state, action) => {
      state.purchasedCourses = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getPurchaseCourse.rejected, (state) => {
      state.loading = false;
    });

    // add ENROLL course
    builder.addCase(enrollCourse.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(enrollCourse.fulfilled, (state, action) => {
      state.enrollCourse = action.payload.response;
      state.loading = true;
    });
    builder.addCase(enrollCourse.rejected, (state) => {
      state.loading = null;
    });
  },
});

export default purchaseSlice.reducer;
