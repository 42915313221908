import API from "./API";

export const registerUserAPI = (payload) => {
  return API.post("/user", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const loginAPI = (payload) => {
  return API.post("/login", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserAPI = (payload) => {
  return API.get("/registereduser", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
