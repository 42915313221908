import API from "./API";

export const addSubscriptionPlanAPI = (payload) => {
  return API.post(`/subscription-plan`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const customerPortalAPI = () => {
  return API.post(`/customer-portal`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const paymentSubscriptionAPI = (payload) => {
  return API.post(`/payment-subscription?planName=${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const stripe_webhooksAPI = (payload) => {
  return API.post(`/stripe_webhooks`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const paymentAPI = (courseId) => {
  return API.post(`/payment?courseId=${courseId}&quantity=1`, courseId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
