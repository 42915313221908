import React, { Component } from "react";
import { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

function OAuth2RedirectHandler() {
  const [searchParams, setSearchParams] = useSearchParams();

  // useEffect(() => {
  //     console.log(searchParams.get("token"));
  //     sessionStorage.setItem("token", searchParams.get("accesstoken"));

  //     dispatch(getQuiz());
  //   }, [searchParams]);

  const token = searchParams.get("token");
  const error = searchParams.get("error");

  if (token) {
    sessionStorage.setItem("token", token);
    window.location = "/dashboard";
    // return (
    //   <Navigate
    //     to={{
    //       pathname: "/dashboard",
    //     }}
    //   />
    // );
  } else {
    return (
      <Navigate
        to={{
          pathname: "/",
        }}
      />
    );
  }
}

export default OAuth2RedirectHandler;
