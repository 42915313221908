import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDashboardDataAPI } from "../api/DashboardApi";

const initialState = {
  courseDataDash: null,
  userDataDash: null,
  loading: false,
  dashboardData: null,
};

export const getDashboardData = createAsyncThunk(
  "dashboard/getDashboardData",
  async (rejectWithValue) => {
    try {
      const response = await getDashboardDataAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,

  extraReducers: (builder) => {
    //GET SYLLABUS
    builder.addCase(getDashboardData.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.dashboardData = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getDashboardData.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default dashboardSlice.reducer;
