import API from "./API";

export const getTenantCustomNameAPI =async (hostname) => {
  const removeWww = hostname.replace("www.app.", "");
  return API.get(`/custom-domain/${removeWww}`)
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const addCertificateAPI = (payload) => {
  return API.post(`/custom-domain/certificate?domainName=${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addCustomDomainAPI = (payload) => {
  return API.post(
    `/custom-domain?alternateDomainName=${payload}`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const checkCertificateStatusAPI = (payload) => {
  return API.get(
    `/custom-domain/certificate-status?certificateArn=${payload}`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const checkCustomDomainStatusAPI = (payload) => {
  return API.get(
    `/custom-domain/domain/www/status?distributionId=${payload}`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const checkCustomDomainAppStatusAPI = (payload) => {
  return API.get(
    `/custom-domain/domain/app/status?distributionId=${payload}`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCustomDomainDetailsAPI = (payload) => {
  return API.get(`/custom-domain/details/${payload}`)
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const getCustomDomainDeleteAPI = () => {
  return API.delete(`/custom-domain`)
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};
