import API from "./API";

export const getEmailTemplateAPI = () => {
  return API.get("/email-template")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addEmailTemplateAPI = (payload) => {
  return API.post("/email-template", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateEmailTemplateAPI = (payload) => {
  return API.put("/email-template", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteEmailTemplateAPI = (id) => {
  return API.delete(`/email-template/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSMSTemplateAPI = () => {
  return API.get("/sms-template")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addSMSTemplateAPI = (payload) => {
  return API.post("/sms-template", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSMSTemplateAPI = (payload) => {
  return API.put("/sms-template", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteSMSTemplateAPI = (id) => {
  return API.delete(`/sms-template/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addSendeMailAPI = (payload) => {
  return API.post("/send-email", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addSendSMSAPI = (payload) => {
  return API.post("/send-sms", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addSendWhatsappAPI = (payload) => {
  return API.post("/send-whatsapp", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUsersAPI = () => {
  return API.get("/users")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUsersCoursePurchasedAPI = (id) => {
  return API.get(`/users?hasCourse=1`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const userStateAPI = (payload) => {
  return API.post(
    `/user-state?isActive=${payload.isActive}&username=${payload.email}`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const getBlogAPI = (params) => {
  return API.get(`/blog?page=${params.page}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSingleBlogAPI = (id) => {
  return API.get(`/blog/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addBlogAPI = (payload) => {
  return API.post("/blog", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateBlogAPI = (payload) => {
  return API.put("/blog", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteBlogAPI = (id) => {
  return API.delete(`/blog/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addBlogImgAPI = (payload) => {
  const formData = new FormData();
  formData.append("file", payload.file);

  return API.post("/blog/thumbnail", formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
