import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTwilioInfoAPI, updateTwilioInfoAPI } from "../api/TwilioInfoApi";

const initialState = {
  twilioInfoData: null,
  loading: false,
};

export const updateTwilio = createAsyncThunk(
  "update/twilio",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateTwilioInfoAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getTwilioInfo = createAsyncThunk(
  "get/twilio",
  async (tenantId, { rejectWithValue }) => {
    try {
      const response = await getTwilioInfoAPI(tenantId);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const twilioSlice = createSlice({
  name: "twilio",
  initialState,
  extraReducers: (builder) => {
    //get user
    builder.addCase(getTwilioInfo.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getTwilioInfo.fulfilled, (state, action) => {
      console.log(action.payload.response)
      state.twilioInfoData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getTwilioInfo.rejected, (state) => {
      state.loading = false;
    });

    //update user
    builder.addCase(updateTwilio.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTwilio.fulfilled, (state, action) => {
      state.twilioInfoData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(updateTwilio.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default twilioSlice.reducer;
