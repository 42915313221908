import API from "./API";

export const getDashboardDataAPI = () => {
  return API.get("/dashboard")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
