import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addCertificateSignImgAPI,
  addCourseAPI,
  addCourseThumbnailAPI,
  deleteCourseAPI,
  getCourseAPI,
  getCourseByIdAPI,
  getCourseUserAPI,
  getSearchCourseAPI,
  getSingleCourseAPI,
  updateCourseAPI,
  updateCourseStatusAPI,
} from "../api/courseAPI";

const initialState = {
  course: {},
  courses: [],
  userCourses: [],
  singleCourse: {},
  searchCourses: null,
  loading: false,
  loadingSngCourse: false,
  currentPage: 0,
  totalItems: 0,
  totalPages: 0,
  loadingUpCourse: null,
  addnUpCourseLoading: null,
  addnUpCourseLoading: null,
};

export const addCourse = createAsyncThunk(
  "course/addCourse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addCourseAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);
export const updateCourse = createAsyncThunk(
  "course/updateCourse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateCourseAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getCourse = createAsyncThunk(
  "course/getCourse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCourseAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getCourseById = createAsyncThunk(
  "course/getCourseById",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCourseByIdAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getCourseUser = createAsyncThunk(
  "course/getCourseUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getCourseUserAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getSingleCourse = createAsyncThunk(
  "course/getSingleCourse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSingleCourseAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);
export const getSearchCourse = createAsyncThunk(
  "course/getSearchCourse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSearchCourseAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteCourse = createAsyncThunk(
  "course/deleteCourse",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteCourseAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateCourseStatus = createAsyncThunk(
  "course/updateCourseStatus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateCourseStatusAPI(payload);
   
      return response.data;
    } catch (err) {
     
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addCourseThumbnail = createAsyncThunk(
  "course/addthumbnail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addCourseThumbnailAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addCertificateSignImg = createAsyncThunk(
  "course/addcertificatesignimg",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addCertificateSignImgAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const courseSlice = createSlice({
  name: "course",
  initialState,
  extraReducers: (builder) => {
    //GET cOURSE
    builder.addCase(getCourse.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getCourse.fulfilled, (state, action) => {
      state.courses = action.payload.response.course;
      state.currentPage = action.payload.response.currentPage;
      state.totalItems = action.payload.response.totalItems;
      state.totalPages = action.payload.response.totalPages;
      state.loading = true;
    });
    builder.addCase(getCourse.rejected, (state) => {
      state.loading = false;
    });

    //GET user cOURSE
    builder.addCase(getCourseUser.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getCourseUser.fulfilled, (state, action) => {
      state.userCourses = action.payload.response.course;
      state.currentPage = action.payload.response.currentPage;
      state.totalItems = action.payload.response.totalItems;
      state.totalPages = action.payload.response.totalPages;
      state.loading = true;
    });
    builder.addCase(getCourseUser.rejected, (state) => {
      state.loading = false;
    });

    //GET SINGLE cOURSE
    builder.addCase(getSingleCourse.pending, (state) => {
      state.loadingSngCourse = false;
    });
    builder.addCase(getSingleCourse.fulfilled, (state, action) => {
      state.singleCourse = action.payload.response;
      state.loadingSngCourse = true;
    });
    builder.addCase(getSingleCourse.rejected, (state) => {
      state.loadingSngCourse = false;
    });

    //GET search cOURSE
    builder.addCase(getSearchCourse.pending, (state) => {
      state.loadingSngCourse = false;
    });
    builder.addCase(getSearchCourse.fulfilled, (state, action) => {
      state.searchCourses = action.payload.response;
      state.loadingSngCourse = true;
    });
    builder.addCase(getSearchCourse.rejected, (state) => {
      state.loadingSngCourse = false;
    });

    //GET cOURSE by id
    builder.addCase(getCourseById.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getCourseById.fulfilled, (state, action) => {
      state.course = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getCourseById.rejected, (state) => {
      state.loading = false;
    });

    //add cOURSE
    builder.addCase(addCourse.pending, (state) => {
      state.addCourseLoading = false;
    });
    builder.addCase(addCourse.fulfilled, (state, action) => {
      state.course = action?.payload?.response;
      state.addCourseLoading = true;
    });
    builder.addCase(addCourse.rejected, (state) => {
      state.addCourseLoading = false;
    });

    //update cOURSE
    builder.addCase(updateCourse.pending, (state) => {
      state.addnUpCourseLoading = false;
    });
    builder.addCase(updateCourse.fulfilled, (state, action) => {
      state.course = action.payload.response;
      state.addnUpCourseLoading = true;
    });
    builder.addCase(updateCourse.rejected, (state) => {
      state.addnUpCourseLoading = false;
    });

    //delete cOURSE
    builder.addCase(deleteCourse.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCourse.fulfilled, (state, action) => {
      state.course = action.payload;
      if (
        !action?.payload?.message.includes(
          "Only course which has draft status can be deleted"
        )
      ) {
        const { arg } = action.meta;
        if (arg) {
          state.courses = state.courses.filter((item) => item.courseId !== arg);
          state.totalItems = state.totalItems-1;
        }
      }
      

      state.loading = true;
    });
    builder.addCase(deleteCourse.rejected, (state) => {
      state.loading = false;
    });

    //update cOURSEStatus
    builder.addCase(updateCourseStatus.pending, (state) => {
      state.loadingUpCourse = false;
    
    });
    builder.addCase(updateCourseStatus.fulfilled, (state, action) => {
      state.course = action.payload;
      console.log("succcesssss ")
      state.loadingUpCourse = true;
   
    });
    builder.addCase(updateCourseStatus.rejected, (state) => {
      console.log("errrrrrrorrrr ")
      state.loadingUpCourse = false;
    
    });

    //add thumbnail
    builder.addCase(addCourseThumbnail.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(addCourseThumbnail.fulfilled, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addCourseThumbnail.rejected, (state) => {
      state.loading = false;
    });

     //add certificate Sign Img
     builder.addCase(addCertificateSignImg.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(addCertificateSignImg.fulfilled, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addCertificateSignImg.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default courseSlice.reducer;
