import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addSyllabusAPI,
  deleteSyllabusAPI,
  getSyllabusAPI,
  syllabusArrangeAPI,
  updateSyllabusAPI,
} from "../api/syllabusApi";

const initialState = {
  syllabusTopic: {},
  syllabus: {},
  allSyllabus: [],
  loading: false,
  addnUpdateSyllabusLoading: null,
  syllabusArrange: null,
};

export const getSyllabus = createAsyncThunk(
  "syllabus/getSyllabus",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getSyllabusAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addSyllabus = createAsyncThunk(
  "syllabus/addSyllabus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addSyllabusAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateSyllabus = createAsyncThunk(
  "syllabus/updateSyllabus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateSyllabusAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const syllabusArrange = createAsyncThunk(
  "syllabus/syllabusarrange",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await syllabusArrangeAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteSyllabus = createAsyncThunk(
  "syllabus/deleteSyllabus",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteSyllabusAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const syllabusSlice = createSlice({
  name: "syllabus",
  initialState,
  reducers: {
    syllabusForTopic(state, action) {
      state.syllabusTopic = action.payload;
    },
  },
  extraReducers: (builder) => {
    //GET SYLLABUS
    builder.addCase(getSyllabus.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getSyllabus.fulfilled, (state, action) => {
      state.allSyllabus = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getSyllabus.rejected, (state) => {
      state.loading = false;
    });

    //add SYLLABUS
    builder.addCase(addSyllabus.pending, (state) => {
      state.addnUpdateSyllabusLoading = false;
    });
    builder.addCase(addSyllabus.fulfilled, (state, action) => {
      state.syllabus = action.payload?.response;
      state.syllabusTopic = action.payload?.response;
      state.allSyllabus
        ? state.allSyllabus.push(action.payload.response)
        : (state.allSyllabus = [action.payload.response]);
      state.addnUpdateSyllabusLoading = true;
    });
    builder.addCase(addSyllabus.rejected, (state) => {
      state.addnUpdateSyllabusLoading = false;
    });

    //YLLABUS ARRANGE
    builder.addCase(syllabusArrange.pending, (state) => {
      state.addnUpdateSyllabusLoading = false;
    });
    builder.addCase(syllabusArrange.fulfilled, (state, action) => {
      state.syllabusArrange = action.payload?.response;
      state.addnUpdateSyllabusLoading = true;
    });
    builder.addCase(syllabusArrange.rejected, (state) => {
      state.addnUpdateSyllabusLoading = false;
    });

    //update SYLLABUS
    builder.addCase(updateSyllabus.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSyllabus.fulfilled, (state, action) => {
      state.syllabus = action.payload.response;
      const { arg } = action.meta;
      if (arg) {
        state.allSyllabus = state.allSyllabus.map((item) =>
          item.syllabusId === arg.syllabusId
            ? {
                syllabusId: arg.syllabusId,
                syllabusTopic: arg.syllabusTopic,
                syllabusDescription: arg.syllabusDescription,
              }
            : item
        );
      }
      state.loading = true;
    });
    builder.addCase(updateSyllabus.rejected, (state) => {
      state.loading = false;
    });

    //delete syllabus
    builder.addCase(deleteSyllabus.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSyllabus.fulfilled, (state, action) => {
      state.syllabus = action.payload;
      const { arg } = action.meta;
      if (arg) {
        state.allSyllabus = state.allSyllabus.filter(
          (item) => item.syllabusId !== arg
        );
      }
      state.loading = true;
    });
    builder.addCase(deleteSyllabus.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { syllabusForTopic } = syllabusSlice.actions;
export default syllabusSlice.reducer;
