import API from "./API";

export const getTopicAPI = (id) => {
  return API.get(`/topic/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTopicUserAPI = (payload) => {
  return API.get(`/topic/${payload.courseId}/${payload.syllabusId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addTopicAPI = (payload) => {
  return API.post("/topic", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addMarkCompleteAPI = (data) => {
  return API.post(`/mark-completed/${data.courseId}/${data.topicId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateTopicAPI = (payload) => {
  return API.put("/topic", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const moveTopicAPI = (payload) => {
  return API.put(`/topic-move/${payload.topicsId}/${payload.syllabusId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const topicRangeAPI = (payload) => {
  return API.post("/topic-arrange", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteTopicAPI = (id) => {
  return API.delete(`/topic/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addVideoAPI = async (payload, onUploadProgress) => {
  const formData = new FormData();
  // Object.keys(payload).map((key) => formData.append(key, payload[key]));
  // for (var pair of formData.entries()) {
  //   console.log("pair", pair[0] + ", " + pair[1]);
  // }
  formData.append("videoId", payload.videoId);
  formData.append("file", payload.file);
  try {
    const response = await API.post("/video", formData, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
      },
      onUploadProgress: (progressEvent_1_1) => {
        const progress = Math.round((progressEvent_1_1.loaded * 100) / progressEvent_1_1.total);
        if (onUploadProgress) {
          onUploadProgress(progress);
        }
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const addAudioAPI = async (payload, onUploadProgress) => {
  const formData = new FormData();
  // Object.keys(payload).map((key) => formData.append(key, payload[key]));
  // for (var pair of formData.entries()) {
  //   console.log("pair", pair[0] + ", " + pair[1]);
  // }
  formData.append("audioId", payload.audioId);
  formData.append("file", payload.file);

  try {
    const response = await API.post("/audio", formData, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
      },
      onUploadProgress: (progressEvent_1_1_1) => {
        const progress = Math.round((progressEvent_1_1_1.loaded * 100) / progressEvent_1_1_1.total);
        if (onUploadProgress) {
          onUploadProgress(progress);
        }
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const addVideoThumbnailAPI = (payload) => {
  const formData = new FormData();
  formData.append("videoId", payload.videoId);
  formData.append("file", payload.file);
  return API.post("/video-thumbnail", formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
