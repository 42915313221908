import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addSubscriptionPlanAPI,
  customerPortalAPI,
  paymentAPI,
  paymentSubscriptionAPI,
  stripe_webhooksAPI,
} from "../api/PaymentApi";

const initialState = {
  stripeUrl: "",
  loadingSubs: null,
  loadingPortal: null,
  courseId: "",
};

export const paymentAdd = createAsyncThunk(
  "payment/paymentAdd",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await paymentAPI(payload);
      window.location = `${response.data.response.url}`;
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addSubscriptionPlan = createAsyncThunk(
  "payment/addSubscriptionPlan",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addSubscriptionPlanAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const customerPortal = createAsyncThunk(
  "payment/customerPortal",
  async (rejectWithValue) => {
    try {
      const response = await customerPortalAPI();
      window.location = response.data.response.url;

      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const paymentSubscription = createAsyncThunk(
  "payment/paymentSubscription",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await paymentSubscriptionAPI(payload);
      window.location = response.data.response.url;
      // sessionStorage.removeItem("token");
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const stripe_webhooks = createAsyncThunk(
  "payment/stripe_webhooks",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await stripe_webhooksAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    courseIdForPurchase: (state, action) => {
      state.courseId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // payment
    builder.addCase(paymentAdd.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(paymentAdd.fulfilled, (state, action) => {
      state.stripeUrl = action.payload.response;
      state.loading = true;
    });
    builder.addCase(paymentAdd.rejected, (state) => {
      state.loading = null;
    });

    // payment
    builder.addCase(paymentSubscription.pending, (state) => {
      state.loadingSubs = false;
    });
    builder.addCase(paymentSubscription.fulfilled, (state, action) => {
      state.loadingSubs = true;
    });
    builder.addCase(paymentSubscription.rejected, (state) => {
      state.loadingSubs = null;
    });

    // payment
    builder.addCase(customerPortal.pending, (state) => {
      state.loadingPortal = false;
    });
    builder.addCase(customerPortal.fulfilled, (state, action) => {
      state.loadingPortal = true;
    });
    builder.addCase(customerPortal.rejected, (state) => {
      state.loadingPortal = null;
    });
  },
});

export const { courseIdForPurchase } = paymentSlice.actions;
export default paymentSlice.reducer;
