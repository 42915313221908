import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addQnAAPI,
  getAllQnAAPI,
  getQnAAPI,
  updateQnAAPI,
  updateQnAApproveAPI,
} from "../api/QandAApi";

const initialState = {
  qna: {},
  qnas: [],
  loading: false,
  addQnAloading: null,
};

export const getQnA = createAsyncThunk(
  "qanda/getQnA",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getQnAAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getAllQnA = createAsyncThunk(
  "qanda/getAllQnA",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getAllQnAAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateQnA = createAsyncThunk(
  "qanda/updateQnA",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateQnAAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateQnAApprove = createAsyncThunk(
  "qanda/updateQnAApprove",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateQnAApproveAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addQnA = createAsyncThunk(
  "qanda/addQnA",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addQnAAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const qnaSlice = createSlice({
  name: "qna",
  initialState,
  extraReducers: (builder) => {
    //GET qna
    builder.addCase(getQnA.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getQnA.fulfilled, (state, action) => {
      state.qnas = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getQnA.rejected, (state) => {
      state.loading = false;
    });

    //GET ALL qna
    builder.addCase(getAllQnA.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllQnA.fulfilled, (state, action) => {
      state.qnas = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getAllQnA.rejected, (state) => {
      state.loading = false;
    });

    //add qna
    builder.addCase(addQnA.pending, (state) => {
      state.addQnAloading = false;
    });
    builder.addCase(addQnA.fulfilled, (state, action) => {
      state.qna = action.payload.response;
      state.qnas
        ? state.qnas.push(action.payload.response)
        : (state.qnas = [action.payload.response]);
      state.addQnAloading = true;
    });
    builder.addCase(addQnA.rejected, (state) => {
      state.addQnAloading = null;
    });

    //update qna
    builder.addCase(updateQnA.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateQnA.fulfilled, (state, action) => {
      state.qna = action.payload.response;
      const { arg } = action.meta;

      if (arg) {
        state.qnas = state.qnas.map((item) =>
          item.qAndaModelId === arg.qAndaModelId
            ? {
                answer: arg.answer,
                courseId: arg.courseId,
                qAndaModelId: arg.qAndaModelId,
                question: arg.question,
              }
            : item
        );
      }
      state.loading = true;
    });
    builder.addCase(updateQnA.rejected, (state) => {
      state.loading = false;
    });

    //update qna APPROVE
    builder.addCase(updateQnAApprove.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateQnAApprove.fulfilled, (state, action) => {
      state.qna = action.payload.response;
      const { arg } = action.meta;

      if (arg) {
        state.qnas = state.qnas.map((item) =>
          item.qAndaModelId === arg.qAndaModelId
            ? {
                ...item,
                qAndaModelId: arg.qAndaModelId,
                isApproved: arg.approvalFlag,
              }
            : item
        );
      }
      state.loading = true;
    });
    builder.addCase(updateQnAApprove.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default qnaSlice.reducer;
