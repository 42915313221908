import API from "./API";

export const getSubscriptionPlanAPI = () => {
  return API.get(`/subscription/plan`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSubscriptionAccountAPI = () => {
  return API.get(`/subscription/account`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const subscriptionAddOnAPI = () => {
  return API.post("/subscription/addon")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSettingSubscriptionAPI = () => {
  return API.post(`/customer-portal?type=1`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const cancelSubscriptionAPI = () => {
  return API.post(`/customer-portal?type=2`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSubscriptionAPI = (payload) => {
  return API.post(
    `/customer-portal?type=3&planName=${payload.planName}&isYearly=${payload.isYearly}`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSubscriptionForTrailAPI = (payload) => {
  return API.post(
    `/payment-subscription?planName=${payload.planName}&isYearly=${payload.isYearly}&isWebsite=0`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
