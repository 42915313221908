import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addAudioAPI,
  addMarkCompleteAPI,
  addTopicAPI,
  addVideoAPI,
  addVideoThumbnailAPI,
  deleteTopicAPI,
  getTopicAPI,
  getTopicUserAPI,
  moveTopicAPI,
  topicRangeAPI,
  updateTopicAPI,
} from "../api/topicApi";

const initialState = {
  topic: {},
  video: "",
  audio: "",
  allTopics: [],
  userAllTopics: [],
  loading: false,
  message: "",
  addNUpdateTopicLoading: null,
  loadingMarkAsComplete: null,
  totalCompleteCourse: null,
  videoLoading: null,
  audioLoading: null,
  videoThumbnailLoading: null,
  videoThumbnail: "",
  topicArrange: null,
  videoUploadProgress: 0,
  audioUploadProgress: 0
};

export const getTopic = createAsyncThunk(
  "topic/getTopic",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getTopicAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getUserTopic = createAsyncThunk(
  "topic/getUserTopic",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getTopicUserAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateTopic = createAsyncThunk(
  "topic/updateTopic",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateTopicAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const moveTopic = createAsyncThunk(
  "topic/moveTopic",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await moveTopicAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const topicRange = createAsyncThunk(
  "topic/topicarrange",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await topicRangeAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteTopic = createAsyncThunk(
  "topic/deleteTopic",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteTopicAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addTopic = createAsyncThunk(
  "topic/addTopic",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addTopicAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addMarkCompleteTopic = createAsyncThunk(
  "topic/markascompleted",
  async (data, { rejectWithValue }) => {
    try {
      const response = await addMarkCompleteAPI(data);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addVideo = createAsyncThunk(
  "video/addVideo",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await addVideoAPI(payload, (progress) => {
        dispatch(setVideoUploadProgress(progress));
      });
      payload.handleClose();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addAudio = createAsyncThunk(
  "audio/addAudio",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await addAudioAPI(payload, (progress) => {
        dispatch(setAudioUploadProgress(progress));
      });
      payload.handleClose();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addVideoThumbnail = createAsyncThunk(
  "topic/addVideoThumbnail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addVideoThumbnailAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const topicSlice = createSlice({
  name: "topic",
  initialState,
  reducers: {
    setAudioUploadProgress: (state, action) => {
      state.audioUploadProgress = action.payload;
    },
    setVideoUploadProgress: (state, action) => {
      state.videoUploadProgress = action.payload;
    },
    resetAudioProgress:(state)=>{
      state.audioUploadProgress =0;
    },
    resetVideoProgress:(state)=>{
      state.videoUploadProgress =0;
    }
  },
  extraReducers: (builder) => {
    //GET TOPIC
    builder.addCase(getTopic.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getTopic.fulfilled, (state, action) => {
      state.allTopics = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getTopic.rejected, (state) => {
      state.loading = false;
    });

    //GET USER TOPIC
    builder.addCase(getUserTopic.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserTopic.fulfilled, (state, action) => {
      state.userAllTopics = action.payload?.response;
      state.message = action.payload?.message && action.payload?.message;
      state.loading = true;
    });
    builder.addCase(getUserTopic.rejected, (state) => {
      state.loading = false;
    });

    //add TOPIC
    builder.addCase(addTopic.pending, (state) => {
      state.addNUpdateTopicLoading = false;
    });
    builder.addCase(addTopic.fulfilled, (state, action) => {
      state.topic = action?.payload?.response;
      state.allTopics
        ? state.allTopics.push(action.payload.response)
        : (state.allTopics = [action.payload.response]);
      state.addNUpdateTopicLoading = true;
    });
    builder.addCase(addTopic.rejected, (state) => {
      state.addNUpdateTopicLoading = null;
    });

    // TOPIC arrange
    builder.addCase(topicRange.pending, (state) => {
      state.addNUpdateTopicLoading = false;
    });
    builder.addCase(topicRange.fulfilled, (state, action) => {
      state.topicArrange = action?.payload?.response;
      state.addNUpdateTopicLoading = true;
    });
    builder.addCase(topicRange.rejected, (state) => {
      state.addNUpdateTopicLoading = null;
    });

    //add addMarkComplete
    builder.addCase(addMarkCompleteTopic.pending, (state) => {
      state.loadingMarkAsComplete = false;
    });
    builder.addCase(addMarkCompleteTopic.fulfilled, (state, action) => {
      state.loadingMarkAsComplete = true;
      state.totalCompleteCourse =
        action?.payload?.response?.courseCompletionPercentage;
    });
    builder.addCase(addMarkCompleteTopic.rejected, (state) => {
      state.loadingMarkAsComplete = null;
    });

    //update tOPIC
    builder.addCase(updateTopic.pending, (state) => {
      state.addNUpdateTopicLoading = false;
    });
    builder.addCase(updateTopic.fulfilled, (state, action) => {
      state.syllabus = action.payload.response;
      const { arg } = action.meta;
      if (arg) {
        state.allTopics = state.allTopics.map((item) =>
          item.topicId === arg.topicId
            ? {
              topicId: arg.topicId,
              topicName: arg.topicName,
              topicDescription: arg.topicDescription,
              topicType: arg.topicType,
              video: arg.videoDTO,
              article: arg.articleDTO,
              syllabus: arg.syllabus,
            }
            : item
        );
      }
      state.addNUpdateTopicLoading = true;
    });
    builder.addCase(updateTopic.rejected, (state) => {
      state.addNUpdateTopicLoading = null;
    });

    //MOVE TOPIC
    builder.addCase(moveTopic.pending, (state) => {
      state.addNUpdateTopicLoading = false;
    });
    builder.addCase(moveTopic.fulfilled, (state, action) => {
      state.syllabus = action.payload.response;
      const { arg } = action.meta;
      console.log(arg);
      if (arg) {
        state.allTopics = state.allTopics.filter(
          (item) => item.topicId !== arg.topicsId
        );
      }
      state.addNUpdateTopicLoading = true;
    });
    builder.addCase(moveTopic.rejected, (state) => {
      state.addNUpdateTopicLoading = null;
    });

    //delete Topic
    builder.addCase(deleteTopic.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTopic.fulfilled, (state, action) => {
      state.topic = action.payload;

      const { arg } = action.meta;
      if (arg) {
        state.allTopics = state.allTopics.filter(
          (item) => item.topicId !== arg
        );
      }
      state.loading = true;
    });
    builder.addCase(deleteTopic.rejected, (state) => {
      state.loading = false;
    });

    //add Video
    builder.addCase(addVideo.pending, (state) => {
      state.videoLoading = false;
      state.videoUploadProgress = 0;
    });
    builder.addCase(addVideo.fulfilled, (state, action) => {
      state.video = action.payload;
      state.videoLoading = true;
      state.videoUploadProgress = 100;
    });
    builder.addCase(addVideo.rejected, (state) => {
      state.videoLoading = null;
      state.videoUploadProgress = 0;
    });

    //add audio
    builder.addCase(addAudio.pending, (state) => {
      state.audioLoading = false;
      state.audioUploadProgress = 0;
    });
    builder.addCase(addAudio.fulfilled, (state, action) => {
      state.audio = action.payload;
      state.audioLoading = true;
      state.audioUploadProgress = 100;
    });
    builder.addCase(addAudio.rejected, (state) => {
      state.audioLoading = null;
      state.audioUploadProgress = 0;
    });

    //add Thumbnail img
    builder.addCase(addVideoThumbnail.pending, (state) => {
      state.videoThumbnailLoading = false;
    });
    builder.addCase(addVideoThumbnail.fulfilled, (state, action) => {
      state.videoThumbnail = action.payload?.response;
      state.videoThumbnailLoading = true;
    });
    builder.addCase(addVideoThumbnail.rejected, (state) => {
      state.videoThumbnailLoading = null;
    });
  },
});

export default topicSlice.reducer;
export const { setVideoUploadProgress, setAudioUploadProgress,resetAudioProgress,resetVideoProgress } = topicSlice.actions;
