import API from "./API";

export const getAllOptionAPI = (payload) => {
  return API.get(`/options/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const addOptionAPI = (payload) => {
  return API.post("/options", payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const updateOptionAPI = (payload) => {
  return API.put("/options", payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteOptionAPI = (payload) => {
  return API.delete(`/options${payload}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
