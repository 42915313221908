import API from "./API";

export const updateChangePasswordAPI = (payload) => {
  return API.put("/change-password", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addPasswordAPI = (payload) => {
  return API.put("/add-password", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateUserDetailsAPI = (payload) => {
  return API.put("/user-details", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
