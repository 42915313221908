import API from "./API";

export const getAllRnRAPI = () => {
  return API.get(`/rating-review/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getRnRAPI = (courseId) => {
  return API.get(`/rating-review/${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addRnRAPI = (payload) => {
  return API.post(`/rating-review`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteRnRAPI = (ratingId) => {
  return API.delete(`/rating-review/${ratingId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
