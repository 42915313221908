import API from "./API";

export const getCurrentTenantAPI = (tenantId) => {
  return API.get(`/tenants/${tenantId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const getCurrentTenantByNameAPI = (tenantName) => {
  return API.get(`/tenants/name/${tenantName}`)
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const updateTenantAPI = (payload) => {
  return API.put("/tenants", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};
