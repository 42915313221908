import API from "./API";

export const getSeoAPI = (params) => {
  return API.get(`/seo-pages?page=${params.page}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSingleSeoAPI = (seoPagesTitle) => {
  return API.get(`/seo-pages/title/${seoPagesTitle}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addSeoAPI = (payload) => {
  return API.post("/seo-pages", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSeoAPI = (payload) => {
  return API.put("/seo-pages", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteSeoAPI = (id) => {
  return API.delete(`/seo-pages/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addSeoImgAPI = (payload) => {
  const formData = new FormData();
  formData.append("file", payload.file);

  return API.post("/seo-pages/thumbnail", formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
