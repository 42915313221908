import React from "react";
import CertificateDesignFour from "../components/Certificate/CertificateDesignFour";
import CertificateDesignFive from "../components/Certificate/CertificateDesignFive";

function CertificateTest() {
  return (
    <div>
      <CertificateDesignFive />
    </div>
  );
}

export default CertificateTest;
