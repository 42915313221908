import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCurrentTenantAPI,
  getCurrentTenantByNameAPI,
  updateTenantAPI,
} from "../api/TenantApi";

const initialState = {
  userData: null,
  loading: false,
};

export const updateTenant = createAsyncThunk(
  "update/tenant",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateTenantAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getCurrentTenant = createAsyncThunk(
  "get/current/tenant",
  async (tenantId, { rejectWithValue }) => {
    try {
      const response = await getCurrentTenantAPI(tenantId);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getCurrentTenantByName = createAsyncThunk(
  "get/current/tenantbyname",
  async (tenantName, { rejectWithValue }) => {
    try {
      const response = await getCurrentTenantByNameAPI(tenantName);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  extraReducers: (builder) => {
    //get user
    builder.addCase(getCurrentTenant.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getCurrentTenant.fulfilled, (state, action) => {
      console.log(action.payload.response);
      state.userData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getCurrentTenant.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCurrentTenantByName.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getCurrentTenantByName.fulfilled, (state, action) => {
      state.userData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getCurrentTenantByName.rejected, (state) => {
      state.loading = false;
    });

    //update user
    builder.addCase(updateTenant.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTenant.fulfilled, (state, action) => {
      state.userData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(updateTenant.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default tenantSlice.reducer;
