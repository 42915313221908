import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addPasswordAPI,
  updateChangePasswordAPI,
  updateUserDetailsAPI,
} from "../api/SettingApi";

const initialState = {
  userDetails: null,
  loading: null,
  loadingAddPass: null,
};

export const updateChangePassword = createAsyncThunk(
  "setting/updateChangePassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateChangePasswordAPI(payload);
      if (response.status === 200) {
        window.location = "/login";
      }
      if (response.response.data.status === 500) {
        return response.response.data;
      }
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addPassword = createAsyncThunk(
  "setting/addPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addPasswordAPI(payload);
      // window.location = "/login";
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "setting/updateUserDetails",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateUserDetailsAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const settingSlice = createSlice({
  name: "setting",
  initialState,

  extraReducers: (builder) => {
    //update user
    builder.addCase(updateUserDetails.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      state.userDetails = action.payload.response;
      state.loading = true;
    });
    builder.addCase(updateUserDetails.rejected, (state) => {
      state.loading = null;
    });

    //update password
    builder.addCase(updateChangePassword.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateChangePassword.fulfilled, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateChangePassword.rejected, (state) => {
      state.loading = null;
    });

    //add password
    builder.addCase(addPassword.pending, (state) => {
      state.loadingAddPass = false;
    });
    builder.addCase(addPassword.fulfilled, (state, action) => {
      state.loadingAddPass = true;
    });
    builder.addCase(addPassword.rejected, (state) => {
      state.loadingAddPass = null;
    });
  },
});

export default settingSlice.reducer;
