import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loginAPI, registerUserAPI, getUserAPI } from "../api/loginApi";

const initialState = {
  user: null,
  userFlag: false,
  login: null,
  loginFlag: false,
};

export const addUser = createAsyncThunk("login/addUser", async (payload) => {
  const res = await registerUserAPI(payload);
  return res.data;
});

export const loginUser = createAsyncThunk(
  "login/loginUser",
  async (payload) => {
    const res = await loginAPI(payload);
    return res.data;
  }
);

export const getUser = createAsyncThunk("login/getUser", async (payload) => {
  const res = await getUserAPI(payload);
  return res.data;
});

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUserFlag: (state) => {
      state.userFlag = false;
    },
    setLoginFlag: (state) => {
      state.login = null;
    },
  },
  extraReducers: (builder) => {
    //register user
    builder.addCase(addUser.pending, (state, action) => {
      state.userFlag = false;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.user = action.payload.response;
      state.userFlag = true;
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.userFlag = false;
    });

    //login user
    builder.addCase(loginUser.pending, (state, action) => {
      state.loginFlag = false;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.login = action.payload.response.token;
      state.loginFlag = true;
      sessionStorage.setItem("token", action.payload.response.token);
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loginFlag = false;
    });

    //get user
    builder.addCase(getUser.pending, (state, action) => {
      state.userFlag = false;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload.response;
      state.userFlag = true;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.userFlag = false;
    });
  },
});

export const { setUserFlag, setLoginFlag } = loginSlice.actions;
export default loginSlice.reducer;
