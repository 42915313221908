import API from "./API";

export const getEventAPI = (params) => {
  return API.get(`/events?page=${params.page}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getEventBlogAPI = (id) => {
  return API.get(`/events/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addEventAPI = (payload) => {
  return API.post("/events", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateEventAPI = (payload) => {
  return API.put("/events", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteEventAPI = (id) => {
  return API.delete(`/events/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addEventImgAPI = (payload) => {
  const formData = new FormData();
  formData.append("file", payload.file);

  return API.post("/events/thumbnail", formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
