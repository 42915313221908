import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  cancelSubscriptionAPI,
  getSubscriptionAccountAPI,
  getSubscriptionPlanAPI,
  subscriptionAddOnAPI,
  updateSettingSubscriptionAPI,
  updateSubscriptionAPI,
  updateSubscriptionForTrailAPI,
} from "../api/SubscriptionAPI";

const initialState = {
  subscriptionData: [],
  subscriptionAccountData: null,
  addOnData: null,
  customerPortalData: null,
};

export const getSubscriptionPlan = createAsyncThunk(
  "subscription/getSubscriptionPlan",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSubscriptionPlanAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getSubscriptionAccount = createAsyncThunk(
  "subscription/getSubscriptionAccount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSubscriptionAccountAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const subscriptionAddOn = createAsyncThunk(
  "subscription/subscriptionAddOn",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await subscriptionAddOnAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateSettingSubscription = createAsyncThunk(
  "subscription/updateSettingSubscription",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateSettingSubscriptionAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "subscription/updateSubscription",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateSubscriptionAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateSubscriptionForTrail = createAsyncThunk(
  "subscription/updateSubscriptionForTrail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateSubscriptionForTrailAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscription/cancelSubscription",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await cancelSubscriptionAPI();
      return response;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,

  extraReducers: (builder) => {
    //getSubscriptionPlan
    builder.addCase(getSubscriptionPlan.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getSubscriptionPlan.fulfilled, (state, action) => {
      state.subscriptionData = action?.payload?.response;
      state.loading = true;
    });
    builder.addCase(getSubscriptionPlan.rejected, (state) => {
      state.loading = false;
    });

    // getSubscriptionAccount
    builder.addCase(getSubscriptionAccount.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getSubscriptionAccount.fulfilled, (state, action) => {
      state.subscriptionAccountData = action?.payload?.response;
      state.loading = true;
    });
    builder.addCase(getSubscriptionAccount.rejected, (state) => {
      state.loading = false;
    });

    //subscriptionAddOn
    builder.addCase(subscriptionAddOn.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(subscriptionAddOn.fulfilled, (state, action) => {
      state.addOnData = action?.payload?.response;
      state.loading = true;
    });
    builder.addCase(subscriptionAddOn.rejected, (state) => {
      state.loading = false;
    });

    //updateSettingSubscription
    builder.addCase(updateSettingSubscription.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSettingSubscription.fulfilled, (state, action) => {
      state.customerPortalData = action?.payload?.response;
      console.log(action?.payload?.response);
      state.loading = true;
    });
    builder.addCase(updateSettingSubscription.rejected, (state) => {
      state.loading = false;
    });

    //updateSubscription
    builder.addCase(updateSubscription.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSubscription.fulfilled, (state, action) => {
      state.customerPortalData = action?.payload?.response;
      console.log(action?.payload?.response);
      state.loading = true;
    });
    builder.addCase(updateSubscription.rejected, (state) => {
      state.loading = false;
    });

    //updateSubscriptionForTrail
    builder.addCase(updateSubscriptionForTrail.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSubscriptionForTrail.fulfilled, (state, action) => {
      state.customerPortalData = action?.payload?.response;
      console.log(action?.payload?.response);
      state.loading = true;
    });
    builder.addCase(updateSubscriptionForTrail.rejected, (state) => {
      state.loading = false;
    });

    //cancelSubscription
    builder.addCase(cancelSubscription.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(cancelSubscription.fulfilled, (state, action) => {      
      state.customerPortalData = action?.payload?.response;
      state.loading = true;
    });
    builder.addCase(cancelSubscription.rejected, (state, action) => {      
      state.loading = false;
    });
  },
});

export default subscriptionSlice.reducer;
