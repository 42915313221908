import API from "./API";

export const getQnAAPI = (courseId) => {
  return API.get(`/q-and-a/${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllQnAAPI = () => {
  return API.get(`/q-and-a/all`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addQnAAPI = (payload) => {
  return API.post(`/q-and-a/question`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateQnAAPI = (payload) => {
  return API.put(`/q-and-a/answer`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateQnAApproveAPI = (payload) => {
  return API.put("/q-and-a/approve", null, {
    params: {
      ...payload,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

// export const updateQnAApproveAPI = ({ approvalFlag, qAndaModelId }) => {
//   console.log("payload update", approvalFlag, qAndaModelId);

//   return API.put(
//     `/qanda/approve?approvalFlag=${approvalFlag}&qAndaModelId=${qAndaModelId}`
//   )
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       console.log(error);
//       throw error;
//     });
// };
