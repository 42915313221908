import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addSeoAPI,
  addSeoImgAPI,
  deleteSeoAPI,
  getSeoAPI,
  getSingleSeoAPI,
  updateSeoAPI,
} from "../api/SeoApi";

const initialState = {
  seos: [],
  seo: {},
  seoImg: null,

  userCourse: [],

  loading: false,

  addnUpSeoLoading: null,
};

export const getSeo = createAsyncThunk(
  "seo/getSeo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSeoAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getSingleSeo = createAsyncThunk(
  "seo/getSingleSeo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSingleSeoAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addSeo = createAsyncThunk(
  "seo/addSeo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addSeoAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateSeo = createAsyncThunk(
  "seo/updateSeo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateSeoAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteSeo = createAsyncThunk(
  "seo/deleteSeo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteSeoAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addSeoImg = createAsyncThunk(
  "seo/addSeoImg",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addSeoImgAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const seoSlice = createSlice({
  name: "seo",
  initialState,

  extraReducers: (builder) => {
    //GET seo
    builder.addCase(getSeo.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getSeo.fulfilled, (state, action) => {
      state.seos = action?.payload?.response;
      state.loading = true;
    });
    builder.addCase(getSeo.rejected, (state) => {
      state.loading = false;
    });

    //GET seo
    builder.addCase(getSingleSeo.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getSingleSeo.fulfilled, (state, action) => {
      state.seo = action?.payload?.response;
      state.loading = true;
    });
    builder.addCase(getSingleSeo.rejected, (state) => {
      state.loading = false;
    });

    //add seo
    builder.addCase(addSeo.pending, (state) => {
      state.addnUpSeoLoading = false;
    });
    builder.addCase(addSeo.fulfilled, (state, action) => {
      state.seo = action?.payload?.response;
      state?.seos?.seopages
        ? state?.seos?.seopages.push(action?.payload.response)
        : (state.seos.seopages = [action?.payload.response]);
      state.addnUpSeoLoading = true;
    });
    builder.addCase(addSeo.rejected, (state) => {
      state.addnUpSeoLoading = null;
    });

    //update seo
    builder.addCase(updateSeo.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSeo.fulfilled, (state, action) => {
      state.seo = action?.payload?.response;
      const { arg } = action?.meta;
      if (arg) {
        state.seos = {
          currentPage: state?.seos?.currentPage,
          totalItems: state?.seos?.totalItems,
          totalPages: state?.seos?.totalPages,
          seo: state?.seos?.seopages?.map((item) =>
            Number(item?.seoid) === Number(arg?.seoid)
              ? {
                  seoPagesUrl: arg?.seoPagesUrl,
                  seoPagesDescription: arg?.seoPagesDescription
                    ? arg?.seoPagesDescription
                    : "",
                  seoPagesId: arg?.seoPagesId,
                  seoPagesTitle: arg?.seoPagesTitle,
                  featuredImage: arg?.featuredImage,
                  thumbnailImage: arg?.thumbnailImage,
                  metaDescription: arg?.metaDescription,
                  metaKeyWord: arg?.metaKeyWord,
                  seoPagesTags: arg?.seoPagesTags,
                }
              : item
          ),
        };
      }
      state.loading = true;
    });
    builder.addCase(updateSeo.rejected, (state) => {
      state.loading = false;
    });

    //delete seo
    builder.addCase(deleteSeo.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSeo.fulfilled, (state, action) => {
      state.seo = action?.payload;
      const { arg } = action?.meta;
      if (arg) {
        state.seos.seopages = state.seos.seopages.filter(
          (item) => item?.seoPagesId !== arg
        );
      }
      state.loading = true;
    });
    builder.addCase(deleteSeo.rejected, (state) => {
      state.loading = false;
    });

    //add seo img
    builder.addCase(addSeoImg.pending, (state) => {
      state.seoImg = false;
    });
    builder.addCase(addSeoImg.fulfilled, (state, action) => {
      state.seoImg = action?.payload?.response;
      state.addnUpSeoLoading = true;
    });
    builder.addCase(addSeoImg.rejected, (state) => {
      state.seoImg = null;
    });
  },
});

export default seoSlice.reducer;
