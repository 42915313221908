import React, { useEffect } from "react";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import WebFont from "webfontloader";

import TopLeft from "../../assets/images/top-left-design.png";
import BottomLeft from "../../assets/images/bottom-left-deign.png";
import TopRight from "../../assets/images/top-rigt-deign.png";
import BottomRight from "../../assets/images/bottom-right.png";

import Achiv from "../../assets/images/certificate-medal-four.png";
import Logo from "../../assets/Logo/logo-trans.png";
import Director1 from "../../assets/Icon/sing1.png";
import Decoration from "../../assets/images/four-deisgn-certi.png";
import Director2 from "../../assets/Icon/sing2.png";

export default function CertificateDesignFour({
  refi,
  userName = "John Do",
  certificateName = "Certificate Name",
  certificateNumber = "0000-0000",
  certificateDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus nulla tellus, vitae vitae amet fusce. Nam nec aliquet nunc vitae eget mattis. Sem nec ut nisi, et malesuada sapien. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  certificateCreationDate = "2024-01-27T16:33:01.000+00:00",
  certificateValidityDate = "2027-01-27T16:33:01.000+00:00",
  courseName = "Course Name",
   certificateLogo="",
   certificateSign="",
     certificateDesignation="Director"
}) {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Libre Baskerville", "Roxborough CF"],
      },
    });
  }, []);

  return (
    <Box>
      <Box
        ref={refi}
        sx={{
          width: "794px",
          height: "560px",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          background: "#FFEDEC",
          overflow: "hidden",
        }}
      >
        <img
          src={TopLeft}
          alt="achievement"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "250px",
            objectFit: "contain",
            zIndex: 1,
          }}
        />
        <img
          src={TopRight}
          alt="achievement"
          style={{
            position: "absolute",
            top: -15,
            right: -10,
            width: "250px",
            objectFit: "contain",
            zIndex: 1,
          }}
        />
        <img
          src={BottomLeft}
          alt="achievement"
          style={{
            position: "absolute",
            bottom: -200,
            left: 0,
            width: "250px",
            objectFit: "contain",
            zIndex: 1,
          }}
        />
        <img
          src={BottomRight}
          alt="achievement"
          style={{
            position: "absolute",
            bottom: -80,
            right: -20,
            width: "250px",
            objectFit: "contain",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            padding: "50px 80px 0px 80px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "470px",
          }}
        >
          <Box
            sx={{
              position: "relative",
              background: "#ffffff90",
              padding: "40px 20px",
              width: "100%",
              height: "100%",
              zIndex: 10,
              borderRadius: "20px",
            }}
          >
            <img
              src={Decoration}
              alt="achievement"
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: 20,
                width: "30px",
                height: "auto",
                objectFit: "contain",
                zIndex: 1,
              }}
            />
            <img
              src={Decoration}
              alt="achievement"
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: 20,
                width: "30px",
                height: "auto",
                objectFit: "contain",
                zIndex: 1,
              }}
            />
            <Box sx={{ position: "absolute", top: "0px", left: "4px" }}>
              <img src={certificateLogo?.length!=0? certificateLogo: Logo} width="100px" alt="achievement" />
            </Box>
            <Box sx={{ textAlign: "center", mt: "40px" }}>
              <Typography
                variant="h1"
                className="font-LibreBaskerville"
                sx={{
                  fontSize: "28px",
                  fontWeight: 700,
                  color: "#B941A1",
                  top: "10px",

                  margin: "0 auto",
                }}
              >
                {certificateName.toUpperCase()}
              </Typography>

              <Typography
                variant="h4"
                className="font-LibreBaskerville"
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#004aad",
                  mt: "20px",
                }}
              >
                This certificate is awarded to
              </Typography>

              <Typography
                variant="h2"
                className="font-LibreBaskerville"
                sx={{
                  fontSize: "36px",
                  fontWeight: 400,
                  color: "#1c569b",
                  mt: "20px",
                  textTransform: "capitalize",
                }}
              >
                {userName}
              </Typography>
              <hr
                style={{ width: "100px", height: "1px", background: "#F28794" }}
              />
              <Typography
                variant="h4"
                className="font-Roxborough"
                sx={{
                  fontSize: "16px",
                  width: "85%",

                  fontWeight: 400,
                  color: "#004aad",
                  mt: "20px",
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 auto",
                  paddingTop: "10px",
                }}
              >
                {/* date in this format 17 June 2021 */}
                {`who has successfully completed the ${courseName} `}
                <br></br>
                {`on  ${
                  certificateCreationDate
                    ? moment(certificateCreationDate).format("DD MMMM YYYY")
                    : "-"
                }`}
              </Typography>
              {/* <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#3A3A3A",
                  mt: "20px",
                  mx: "80px",
                }}
              >
                {certificateDescription}
              </Typography> */}

              <Box
                sx={{
                  position: "absolute",
                  bottom: "0px",
                  width: "500px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "auto",
                  left: 0,
                  right: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <img
                 src={certificateSign?.length>0? certificateSign : Director1}
                    style={{
                      width: "140px",
                      height: "auto",
                    }}
                    alt="bar"
                  />
                  <hr
                    style={{
                      width: "100px",
                      height: "1px",
                      background: "#F28794",
                    }}
                  />
                  <Typography
                    variant="body1"
                    className="font-Roxborough"
                    sx={{ fontSize: "14px", fontWeight: 500, color: "#004aad" }}
                  >
                    {certificateDesignation?.length>0 ?certificateDesignation : "Director"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "50%",
                      border: "3px solid #EAD6D7",
                      width: "60px",
                      height: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Achiv}
                      style={{
                        width: "40px",
                        height: "auto",
                        objectFit: "contain",
                      }}
                      alt="bar"
                    />
                  </Box>
                  <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                    <span>certificate Number: </span>

                    <span>
            {certificateNumber}
          </span>
                  </span>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <span>Expiry: </span>

                    {certificateValidityDate === certificateCreationDate ? (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        Lifetime
                      </span>
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {certificateValidityDate
                          ? moment(certificateValidityDate).format("DD-MM-YYYY")
                          : "-"}
                      </span>
                    )}
                  </span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                 {certificateCreationDate
                  ? moment(certificateCreationDate).format("DD-MM-YYYY")
                  : "-"}
                  <hr
                    style={{
                      width: "100px",
                      height: "1px",
                      background: "#F28794",
                    }}
                  />
                  <Typography
                    variant="body1"
                    className="font-Roxborough"
                    sx={{ fontSize: "14px", fontWeight: 500, color: "#004aad" }}
                  >
                    Completion Date
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* <Typography
              sx={{
                position: "absolute",
                bottom: "20px",
                fontSize: "12px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <span>
                {certificateNumber?.split("-")[0] +
                  " - " +
                  certificateNumber?.split("-")[1]}
              </span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <span>Expiry: </span>

                {certificateValidityDate === certificateCreationDate ? (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    Lifetime
                  </span>
                ) : (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {certificateValidityDate
                      ? moment(certificateValidityDate).format("DD-MM-YYYY")
                      : "-"}
                  </span>
                )}
              </span>
            </Typography> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
