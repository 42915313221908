import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addToCartAPI,
  deleteCartAPI,
  getCartAPI,
  getCartCountAPI,
} from "../api/CartApi";

const initialState = {
  carts: [],
  cart: null,
  loading: false,
  cartCount: null,
};

export const addToCartPost = createAsyncThunk(
  "addToCart/addToCart",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addToCartAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getCartDetails = createAsyncThunk(
  "addToCart/getCart",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getCartAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteCart = createAsyncThunk(
  "addToCart/deleteCart",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await deleteCartAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getCartCount = createAsyncThunk(
  "addToCart/addtocartcount",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getCartCountAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  extraReducers: (builder) => {
    // add cart
    builder.addCase(addToCartPost.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(addToCartPost.fulfilled, (state, action) => {
      state.cart = action.payload.response;
      state.loading = true;
    });
    builder.addCase(addToCartPost.rejected, (state) => {
      state.loading = false;
    });

    //GET cart
    builder.addCase(getCartDetails.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getCartDetails.fulfilled, (state, action) => {
      state.carts = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getCartDetails.rejected, (state) => {
      state.loading = false;
    });

    //GET cart
    builder.addCase(getCartCount.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getCartCount.fulfilled, (state, action) => {
      state.cartCount = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getCartCount.rejected, (state) => {
      state.loading = false;
    });

    //delete cart
    builder.addCase(deleteCart.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCart.fulfilled, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteCart.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default cartSlice.reducer;
