import API from "./API";

export const signupAPI = (payload) => {
  return API.post("/auth/signup", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const loginAPI = (payload) => {
  return API.post("/auth/login", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const refreshTokenAPI = (payload) => {
  return API.get(`/auth/refresh-token?token=${payload.token}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const forgetPasswordAPI = (payload) => {
  return API.put(`/auth/forgot-password?&email=${payload.email}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};
