import API from "./API";

export const getAllQuizAPI = (payload) => {
  return API.get("/quiz")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getAllQuizByUserAPI = (payload) => {
  return API.get(`/quiz/user/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const downloadQuizExcelAPI = (payload) => {
  return API.get(`/quiz/download-excel`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const importQuizExcelAPI = (payload) => {
  const headers = {
    'Content-Type': 'multipart/form-data'
  }
  return API.post("/import-quiz", payload, {
    headers:headers
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export const addQuizAPI = (payload) => {
  return API.post("/quiz", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteQuizAPI = (payload) => {
  return API.delete(`/quiz/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateQuizAPI = (payload) => {
  return API.put("/quiz", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateQuizPublishAPI = (payload) => {
  return API.put(`/quiz/publish/${payload?.publishId}/${payload?.quizId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
