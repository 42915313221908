import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  forgetPasswordAPI,
  loginAPI,
  refreshTokenAPI,
  signupAPI,
} from "../api/AuthApi";
import jwt_decode from "jwt-decode";

const initialState = {
  login: null,
  loading: null,
  error: {},
  refreshToken: null,
};

export const singup = createAsyncThunk(
  "auth/singup",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await signupAPI(payload);
      return response;
    } catch (err) {
      console.log(err)
      return rejectWithValue({
        errorMessage: err?.response?.data?.message,
      });
    }
  }
);

export const getrefreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await refreshTokenAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await loginAPI(payload);
      if (response.data.success) {
        console.log(response, "response", response.status);
        if (response.status === 202) {
          console.log("response", response);
          throw new Error(response.data.message);
        }
        if (
          jwt_decode(response?.data?.response?.accessToken)?.role === "ADMIN"
        ) {
          window.location = "/admin/dashboard";
        } else if (
          jwt_decode(response?.data?.response?.accessToken)?.role === "STAFF"
        ) {
          window.location = "/admin/manageCourse";
        } else {
          window.location = "/dashboard";
        }

        return response;
      } else {        
        throw new Error(response.data.message);
      }
    } catch (err) {
      console.log(err)
      return rejectWithValue({
        errorMessage: err?.data?.message,
      });
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await forgetPasswordAPI(payload);
      return response;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    //login user
    builder.addCase(login.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.login = action.payload.data.response.accessToken;
      sessionStorage.setItem("token", action.payload.data.response.accessToken);
      sessionStorage.setItem(
        "refreshToken",
        action?.payload?.data?.response?.refreshToken
      );
      state.loading = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = null;
      state.error = action.payload;
    });

    builder.addCase(getrefreshToken.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getrefreshToken.fulfilled, (state, action) => {
      state.refreshToken = action.payload.response.accessToken;
      sessionStorage.setItem("token", action?.payload?.response?.accessToken);
      sessionStorage.setItem(
        "refreshToken",
        action?.payload?.response?.refreshToken
      );
      state.loading = true;
    });
    builder.addCase(getrefreshToken.rejected, (state) => {
      state.loading = false;
    });

    //signup user
    builder.addCase(singup.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(singup.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(singup.rejected, (state) => {
      state.loading = null;
    });
  },
});

export default authSlice.reducer;
