import API from "./API";

export const getWishlistAPI = () => {
  return API.get("/wishlist")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const addWishlistAPI = (id) => {
  return API.post(`/wishlist?courseId=${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteWishlistAPI = (id) => {
  return API.delete(`/wishlist?courseId=${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
